<template>
  <div id="guider">
    <div class="header">
      <van-row gutter="10">
        <van-col span="8" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />添加导游
        </van-col>
        <!-- <van-col span="14" class="search">
          <input
            type="text"
            class="search-input"
            v-model="query.name"
            @blur="onRefresh"
          />
          <svg-icon
            :data_iconName="'search'"
            :className="'search-input-icon'"
          />
        </van-col> -->
        <van-col
          span="16"
          class="header-title"
          v-if="list.length == 1&&active==0&&!list[0].is_current_company_guides"
          style="text-align: right"
          @click="onItemClick(list[0])"
        >
          确认挂靠
        </van-col>
         <van-col
          span="16"
          class="header-title"
          v-if="list1.length == 1&&active==1&&!list1[0].is_current_company_tem_guides"
          style="text-align: right"
          @click="onTempClick(list1[0])"
        >
          确认借调
        </van-col>
      </van-row>
    </div>
    <van-tabs v-model="active" color="#fff" title-active-color="#333" type="card" background="#6c94f6">
      <van-tab title="挂靠">
        <div class="search-container">
          <van-row>
            <van-col span="24" class="search-input-container">
              <input
                type="text"
                class="search-input-add"
                v-model="query.card_mobile"
                @blur="onRefresh"
                placeholder="请输入导游手机号或导游证号"
              />
              <svg-icon
                :data_iconName="'search'"
                :className="'search-input-icon-add'"
              />
            </van-col>
          </van-row>
        </div>
        <div
          class="guider-container"
          v-for="(guide, index) in list"
          :key="index"
        >
          <van-image
            class="guider-avatar"
            round
            :src="guide.avatar ? guide.avatar : ''"
            ><template v-slot:error
              ><span style="font-size: 10px">暂无头像</span></template
            ></van-image
          >
          <van-row>
            <van-col span="12"
              ><div class="guider-text text-left">姓名：</div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-right">
                {{ guide.name ? guide.name : "暂无" }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">性别：</div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-right">
                {{ guide.gender ? guide.gender : "暂无" }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">电话：</div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-right">
                {{ guide.mobile ? guide.mobile : "暂无电话" }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">地区：</div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-right">
                <!-- {{ guide.mobile ? guide.mobile : "暂无" }} -->
                {{ guide.province + "/" + guide.city + "/" + guide.district }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">导游证号：</div></van-col
            >

            <van-col span="12"
              ><div class="guider-text text-right">
                {{ guide.tourist_card ? guide.tourist_card : "暂无" }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">导游语种：</div></van-col
            >

            <van-col span="12"
              ><div class="guider-text text-right">
                {{ guide.language ? guide.language : "暂无" }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">导游级别：</div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-right">
                {{ guide.level ? guide.level : "暂无" }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">评分：</div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-right">
                <!-- {{ guide.travel_company ? guide.travel_company : "暂无" }} -->
                <van-rate
                  v-model="guide.rate_star"
                  :size="25"
                  color="#FFAA00"
                  void-icon="star"
                  disabled-color="#eee"
                  readonly
                  allow-half
                /></div
            ></van-col>
            <!-- <van-col span="12"
            ><div class="guider-text text-left">合同：</div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-right">
              {{ guide.travel_company ? guide.travel_company : "暂无" }}
            </div></van-col
          > -->
          </van-row>
        </div>
        <van-row>
          <van-col span="24">
            <van-empty
              image="search"
              v-if="list.length == 0"
              description="暂无数据"
            />
          </van-col>
        </van-row>
      </van-tab>
      <van-tab title="借调">
          <div class="search-container">
          <van-row>
            <van-col span="24" class="search-input-container">
              <input
                type="text"
                class="search-input-add"
                v-model="query1.q"
                @blur="onRefresh1"
                placeholder="请输入姓名或手机号或身份证号码或导游证号"
              />
              <svg-icon
                :data_iconName="'search'"
                :className="'search-input-icon-add'"
              />
            </van-col>
          </van-row>
        </div>
        <div
          class="guider-container"
          v-for="(guide, index) in list1"
          :key="index"
        >
          <van-image
            class="guider-avatar"
            round
            :src="guide.avatar ? guide.avatar : ''"
            ><template v-slot:error
              ><span style="font-size: 10px">暂无头像</span></template
            ></van-image
          >
          <van-row>
            <van-col span="12"
              ><div class="guider-text text-left">姓名：</div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-right">
                {{ guide.name ? guide.name : "暂无" }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">性别：</div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-right">
                {{ guide.gender ? guide.gender : "暂无" }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">电话：</div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-right">
                {{ guide.mobile ? guide.mobile : "暂无电话" }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">地区：</div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-right">
                <!-- {{ guide.mobile ? guide.mobile : "暂无" }} -->
                {{ guide.province + "/" + guide.city + "/" + guide.district }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">导游证号：</div></van-col
            >

            <van-col span="12"
              ><div class="guider-text text-right">
                {{ guide.tourist_card ? guide.tourist_card : "暂无" }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">导游语种：</div></van-col
            >

            <van-col span="12"
              ><div class="guider-text text-right">
                {{ guide.language ? guide.language : "暂无" }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">导游级别：</div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-right">
                {{ guide.level ? guide.level : "暂无" }}
              </div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-left">评分：</div></van-col
            >
            <van-col span="12"
              ><div class="guider-text text-right">
                <!-- {{ guide.travel_company ? guide.travel_company : "暂无" }} -->
                <van-rate
                  v-model="guide.rate_star"
                  :size="25"
                  color="#FFAA00"
                  void-icon="star"
                  disabled-color="#eee"
                  readonly
                  allow-half
                /></div
            ></van-col>
            <!-- <van-col span="12"
            ><div class="guider-text text-left">合同：</div></van-col
          >
          <van-col span="12"
            ><div class="guider-text text-right">
              {{ guide.travel_company ? guide.travel_company : "暂无" }}
            </div></van-col
          > -->
          </van-row>
        </div>
        <van-row>
          <van-col span="24">
            <van-empty
              image="search"
              v-if="list1.length == 0"
              description="暂无数据"
            />
          </van-col>
        </van-row>
      </van-tab>
    </van-tabs>

    <!-- <div class="guider-body">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_no_company_guides"
        >
           <van-row
            class="guider-body-item"
            v-for="(item, index) in list"
            :key="index"
            @click="onItemClick(item)"
          >
            <van-col span="5">
              <van-image
                class="item-avatar"
                round
                :src="item.avatar ? item.avatar : ''"
                ><template v-slot:error
                  ><span style="font-size: 10px">暂无头像</span></template
                ></van-image
              >
            </van-col>
            <van-col span="19">
              <div class="item-name">
                {{ item.name }}
                <div class="lang2" v-if="item.language">{{ item.language }}</div>
                <van-rate
                 v-if="item.rate_star"
                  v-model="item.rate_star"
                  :size="10"
                  color="#FFAA00"
                  void-icon="star"
                  gutter="2px"
                  void-color="#fff"
                  readonly
                  allow-half
                />
              
              </div>
              <div class="item-sub-text">
                {{ item.mobile ? item.mobile : "暂无手机" }}
              </div>
              <div class="item-sub-text">
                {{ item.travel_company ? item.travel_company : "暂无旅行社" }}
              </div>
            </van-col>
          </van-row>
        </van-list>
        <van-empty v-if="list.length == 0" description="暂无导游数据" />
      </van-pull-refresh>
    </div> -->
    <!-- <Tarbar :active="1"></Tarbar> -->
  </div>
</template>

<script>
import Tarbar from "../components/Tarbar.vue";
import { Dialog, Toast } from "vant";
export default {
  name: "guider",
  components: {
    Tarbar,
  },
  data() {
    return {
      list: [],
      list1:[],
      active:0,
      loading: true,
      finished: false,
      refreshing: false,
      query: {
        card_mobile: "",
      },
      query1:{
        q:""
      }
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onItemClick(item) {
      const _self = this;
      Dialog.confirm({
        title: "添加导游",
        message: "确定挂靠该导游吗?",
      })
        .then(() => {
          // on confirm
          _self.add_guide_to_company(item.id);
        })
        .catch(() => {
          // on cancel
        });
    },
    onTempClick(item) {
      const _self = this;
      Dialog.confirm({
        title: "添加导游",
        message: "确定借调该导游吗?",
      })
        .then(() => {
          // on confirm
          _self.add_guide_to_tmp_relation(item.id);
        })
        .catch(() => {
          // on cancel
          // Toast('')
        });
    },
    async add_guide_to_tmp_relation(id){
      const res = await this.$apis.add_guide_to_tmp_relation(id)
      const _self = this;
      if(res.status==200){
        Toast("操作成功,等待导游确认!");
         setTimeout(function () {
          _self.$router.push("/guider");
        }, 2000);
      }
    },
    async add_guide_to_company(id) {
      // const data = {
      //     guide_id:id
      // }
      const _self = this;
      const res = await this.$apis.add_guide_to_company(id);
      if (res.status == 200) {
        Toast("操作成功,等待导游确认!");
        setTimeout(function () {
          _self.$router.push("/guider");
        }, 2000);
      }
    },
    async get_no_company_guides() {
      const res = await this.$apis.get_no_company_guides(this.query);
      if (res.data.length == 0) {
        Toast("没找到该导游");
        return false  //has_company
      }
      if(res.data[0].has_company&&(!res.data[0].is_current_company_guides)){
        Toast("该导游已经挂靠");
        return false  //has_company
      }
      if (res.data.length == 25) {
        this.query.page++;

        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      // for (var i = 0; i < res.data.length; i++) {
        this.list.push(res.data[0]);
      // }
    },
     async search_tmp_guides() {
      const res = await this.$apis.search_tmp_guides(this.query1);
      if (res.data.length == 0) {
        Toast("没找到该导游");
        return false
      }
      if (res.data.length == 25) {
        this.query.page++;

        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      // for (var i = 0; i < res.data.length; i++) {
        this.list1.push(res.data[0]);
      // }
    },
    onRefresh() {
      // 清空列表数据
      if (!this.query.card_mobile) {
        // Toast("请输入导游手机号或导游证号");
        return false;
      }
      this.list = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.get_no_company_guides();
    },
    onRefresh1() {
      // 清空列表数据
      if (!this.query1.q) {
        // Toast("请输入导游手机号或导游证号");
        return false;
      }
      this.list1 = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.search_tmp_guides();
    },
  },
  created() {
    // this.onRefresh();
  },
};
</script>

<style scoped>
#guider{
  height: 100vh;
  padding-top:10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 0.6rem;
}
.search-input-icon {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-0.1rem);
  font-size: 0.4rem;
}
.guider-body {
  background: #ffff;
  height: 90vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2vh;
  padding: 0.4rem;
  overflow-y: scroll;
  padding-bottom: 1.2rem;
}
.guider-body-item {
  width: 100%;
  height: 1.6rem;
  position: relative;
  /* background: chartreuse; */
  z-index: 10;
}
.guider-body-item .item-avatar {
  overflow: hidden;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
}
.guider-body-item .item-avatar img {
  height: 100%;
  height: 100%;
}
.guider-body-item .item-name {
  font-size: 16px;
  color: #333;

  font-weight: bold;
}
.guider-body-item .item-sub-text {
  color: #666;
  font-size: 12px;
}
.lang {
  background: rgba(148, 174, 64, 0.18);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #94ae40;
  font-size: 12px;
  margin-left: 10px;
}
.lang2 {
  background: rgba(75, 183, 78, 0.13);
  border-radius: 9px;
  border-radius: 9px;
  text-align: center;
  display: inline-block;
  padding: 2px 5px;
  color: #4bb74e;
  font-size: 12px;
  margin-left: 10px;
}
.search-container {
  height: 10vh;
  line-height: 10vh;
  padding: 0.4rem;
}
.search-input-container {
  position: relative;
}
.search-input-add {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 1rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 1rem;
  font-size: 0.2rem;
}
::placeholder {
  color: #fff;
}
.search-input-icon-add {
  position: absolute;
  right: 0.2rem;
  top: 0.5rem;
  font-size: 0.6rem;
}
.guider-container {
  padding: 0.4rem;
  background-color: #fff;
  border-radius: 20px;
  border-radius: 20px;
  position: relative;
  width: 90%;
  margin: 0 auto;
  margin-top: 15vh;
  /* height: 80vh; */
}
.guider-avatar {
  position: absolute;
  top: -0.75rem;
  left: 50%;
  transform: translateX(-50%);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
}
.guider-avatar img {
  width: 100%;
  height: 100%;
}

.guider-text {
  font-size: 14px;
  color: #666666;
  margin-top: 0.2rem;
  text-align: center;
}
</style>
